// Thirdparty
import 'swiper/css'

// Theme
import '../styles/index.css'

// Thirdparty
import { on } from 'delegated-events'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Swiper, { Navigation } from 'swiper'

gsap.registerPlugin(ScrollTrigger)
Swiper.use([Navigation])

const getRandom = (arr) => {
  const random = Math.floor(Math.random() * arr.length)

  return arr[random]
}

const setColors = () => {
  const colorsRgb = [
    '#0c7359', // Green Brown
    '#81763b', // Green Blue
    '#35d57a', // Deep Blue
    '#1506f4', // Green Hot
    '#76a4da', // Pastel Blue
    '#7b61ff', // Purple Hot
    '#883c3f', // Maroon
    '#f05f21', // Orange
  ]

  const colorsHsl = [
    '51,37,37', // Green Brown
    '165,81,25', // Green Blue,
    '244,95,49', // Deep Blue,
    '146,66,52', // Green Hot
    '212,57,66', // Pastel Blue
    '250,100,69', // Purple Hot
    '358,39,38', // Maroon
    '18,87,57', // Orange
  ]

  document.querySelectorAll('.bg-color-random').forEach((el) => {
    const color = getRandom(colorsRgb)
    el.style.backgroundColor = color
  })

  document.querySelectorAll('.site-menu-item').forEach((el) => {
    const baseColor = getRandom(colorsHsl)
    const baseHsl = baseColor.split(',')
    const h = parseInt(baseHsl[0])
    const s = parseInt(baseHsl[1])
    let l = parseInt(baseHsl[2])

    el.querySelector('.site-menu-item-title').style.backgroundColor = `hsl(${h}, ${s}%, ${l}%)`

    if (el.classList.contains('has-children')) {
      el.querySelectorAll('.site-menu-sub a span').forEach((anchor) => {
        l += 10
        anchor.style.backgroundColor = `hsl(${h}, ${s}%, ${l}%)`
      })
    }
  })
}

const setHeights = () => {
  const siteLogo = document.querySelector('.site-logo')
  const siteHeader = document.querySelector('.site-header')
  const siteNav = document.querySelector('.site-nav')
  const siteMenu = document.querySelector('.site-menu')

  if (siteHeader) {
    document.documentElement.style.setProperty('--site-header-height', `${siteHeader.offsetHeight}px`)
  }

  if (siteLogo) {
    document.documentElement.style.setProperty('--site-logo-height', `${siteLogo.offsetHeight}px`)
  }

  if (siteNav && siteMenu) {
    document.documentElement.style.setProperty('--site-nav-height', `${siteMenu.offsetHeight}px`)
  }
}

const setupHeader = () => {
  const siteHeader = document.querySelector('.site-header')

  const handler = (entries) => {
    if (!entries[0].isIntersecting) {
      siteHeader.classList.add('is-stuck')
    } else {
      siteHeader.classList.remove('is-stuck')
    }
  }

  const observer = new window.IntersectionObserver(handler)
  observer.observe(siteHeader)
}

const setMenuHoverRotations = () => {
  const siteMenu = document.querySelector('.site-menu')

  siteMenu.querySelectorAll('a').forEach((el) => {
    el.classList.remove('rotate-left', 'rotate-right')

    if (Math.random() < 0.5) {
      el.classList.add('rotate-left')
    } else {
      el.classList.add('rotate-right')
    }
  })
}

const rotateMenuItems = (el) => {
  const rotations = [-8, -6, -4, -2, 2, 4, 6, 8]
  let lastRotation = 0

  el.querySelectorAll('.site-menu-sub a').forEach((anchor) => {
    let newRotation = -1

    do {
      newRotation = getRandom(rotations)
    } while (Math.abs(newRotation - lastRotation) <= 2)

    anchor.style.transform = `rotate(${newRotation}deg)`

    lastRotation = newRotation
  })
}

const setupNavigation = () => {
  const siteMenu = document.querySelector('.site-menu')

  setMenuHoverRotations()

  document.querySelectorAll('.site-menu-item.has-children').forEach((el) => {
    rotateMenuItems(el)
  })

  on('click', '.site-menu-toggle', (e) => {
    const menuTrigger = e.target.closest('.site-menu-toggle')

    if (menuTrigger.getAttribute('aria-expanded') === 'false') {
      siteMenu.classList.add('is-active')
      menuTrigger.setAttribute('aria-expanded', 'true')
      menuTrigger.querySelector('.for-collapsed').classList.add('hidden')
      menuTrigger.querySelector('.for-expanded').classList.remove('hidden')
      document.documentElement.classList.add('has-nav')

      siteMenu.style.height = `calc(100vh - ${siteMenu.getBoundingClientRect().top}px)`
      siteMenu.style.paddingBottom = `${siteMenu.getBoundingClientRect().top}px`

      rotateMenuItems(siteMenu)
    } else {
      siteMenu.classList.remove('is-active')
      menuTrigger.setAttribute('aria-expanded', 'false')
      menuTrigger.querySelector('.for-collapsed').classList.remove('hidden')
      menuTrigger.querySelector('.for-expanded').classList.add('hidden')
      document.documentElement.classList.remove('has-nav')
      siteMenu.style.height = null
      siteMenu.style.paddingBottom = null
    }
  })

  window.addEventListener('resize', () => {
    if (window.matchMedia('(min-width: 768px)').matches) {
      const menuTrigger = document.querySelector('.site-menu-toggle')
      siteMenu.classList.remove('is-active')
      menuTrigger.setAttribute('aria-expanded', 'false')
      menuTrigger.querySelector('.for-collapsed').classList.remove('hidden')
      menuTrigger.querySelector('.for-expanded').classList.add('hidden')
      document.documentElement.classList.remove('has-nav')
      siteMenu.style.height = null
      siteMenu.style.paddingBottom = null
    }
  })

  on('mouseover', '.site-menu-item', (e) => {
    const canInteract = window.matchMedia('(min-width: 768px)').matches
    const menuItem = e.target.closest('.site-menu-item')

    if (!canInteract || menuItem.classList.contains('is-active')) {
      return false
    }

    siteMenu.classList.remove('has-active')

    document.querySelectorAll('.site-menu-item.is-active').forEach((el) => {
      el.classList.remove('is-active')
      el.setAttribute('aria-expanded', 'false')
    })

    if (!menuItem.classList.contains('is-active')) {
      siteMenu.classList.add('has-active')
      menuItem.classList.add('is-active')

      if (menuItem.classList.contains('has-children')) {
        menuItem.setAttribute('aria-expanded', 'true')
        rotateMenuItems(menuItem)

        gsap.fromTo('.site-menu-item.is-active .site-menu-sub li', { opacity: 0 }, { opacity: 1, stagger: 0.1 })
      }
    } else {
      if (menuItem.classList.contains('has-children')) {
        menuItem.setAttribute('aria-expanded', 'false')
      }

      menuItem.classList.remove('is-active')
    }
  })

  on('mouseout', '.site-menu.has-active', (e) => {
    const canInteract = window.matchMedia('(min-width: 768px)').matches

    if (canInteract && e.toElement && !e.toElement.closest('.site-nav')) {
      siteMenu.classList.remove('has-active')

      const menuItem = siteMenu.querySelector('.site-menu-item.is-active')

      if (menuItem) {
        siteMenu.classList.remove('has-active')
        menuItem.setAttribute('aria-expanded', 'false')
        menuItem.classList.remove('is-active')

        setMenuHoverRotations()
      }
    }
  })
}

const setupScroll = () => {
  const marquees = document.querySelectorAll('.marquee')

  marquees.forEach((marquee) => {
    const items = Array.from(marquee.querySelectorAll('.marquee-scroll'))

    items.forEach((item) => {
      const offset = parseFloat(item.dataset.scrollOffset)
      const x = `${-offset * 100}%`
      const xEnd = 0

      gsap.fromTo(
        item,
        { x },
        {
          x: xEnd,
          scrollTrigger: {
            trigger: marquee,
            scrub: 0.3,
          },
        }
      )
    })
  })

  const reveals = document.querySelectorAll('.reveal-scroll-section')

  reveals.forEach((section) => {
    const items = section.querySelectorAll('.reveal-scroll')

    items.forEach((item) => {
      const offset = parseFloat(item.dataset.scrollOffset)
      const y = `${offset * 75}%`

      gsap.fromTo(
        item,
        { y },
        {
          y: 0,
          scrollTrigger: {
            trigger: section,
            start: 'center bottom',
            end: 'top 10%',
            scrub: 0,
          },
        }
      )
    })
  })

  const revealContainers = document.querySelectorAll('.reveal-scroll-container')

  revealContainers.forEach((container) => {
    const items = container.querySelectorAll('.reveal-scroll')

    items.forEach((item) => {
      const offset = parseFloat(item.dataset.scrollOffset)
      const y = `${offset * 75}%`

      gsap.fromTo(
        item,
        { y },
        {
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'center bottom',
            end: 'top 15%',
            scrub: 0,
          },
        }
      )
    })
  })
}

const setupSliders = () => {
  const fn = {
    init: () => {
      const els = document.querySelectorAll('.slider')
      els.forEach((el) => fn.setupSlider(el))
    },

    setupSlider: (el) => {
      const container = el.querySelector('.swiper')
      const numSlides = container.querySelectorAll('.swiper-slide').length

      if (numSlides > 1) {
        new Swiper(container, {
          spaceBetween: 20,
          slidesPerView: 'auto',
          loop: true,
          loopedSlides: numSlides,
          navigation: {
            nextEl: el.querySelector('.slider-button-next'),
            prevEl: el.querySelector('.slider-button-prev'),
          },
          breakpoints: {
            768: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
          },
        })
      }
    },
  }

  fn.init()
}

const setupProduct = () => {
  const fn = {
    init: () => {
      const els = document.querySelectorAll('.product-slider')
      els.forEach((el) => fn.setupSlider(el))

      fn.setupDetails()

      on('click', '.product-image-modal-trigger', fn.openModal)
      on('click', '.product-image-modal-close', fn.closeModal)
      on('click', '.color-toggle', fn.handleColorToggle)
    },

    setupSlider: (el) => {
      const container = el.querySelector('.swiper')
      const numSlides = container.querySelectorAll('.swiper-slide').length

      if (numSlides > 1) {
        new Swiper(container, {
          spaceBetween: 10,
          slidesPerView: 'auto',
          loop: true,
          loopedSlides: numSlides,
          navigation: {
            nextEl: el.querySelector('.slider-button-next'),
            prevEl: el.querySelector('.slider-button-prev'),
          },
          breakpoints: {
            768: {
              spaceBetween: 16,
            },
          },
        })
      }
    },

    setupDetails: () => {
      const el = document.querySelector('.product-details')

      if (el) {
        const container = el.querySelector('.swiper')
        const numSlides = container.querySelectorAll('.swiper-slide').length

        if (numSlides > 1) {
          new Swiper(container, {
            spaceBetween: 36,
            slidesPerView: 1,
            loop: true,
            loopedSlides: numSlides,
            navigation: {
              nextEl: el.querySelector('.slider-button-next'),
              prevEl: el.querySelector('.slider-button-prev'),
            },
            breakpoints: {
              768: {
                slidesPerView: 3,
                enabled: false,
              },
            },
          })
        }
      }
    },

    handleColorToggle: (e) => {
      document.documentElement.classList.toggle('is-inverted')

      const button = e.target.closest('button')

      if (document.documentElement.classList.contains('is-inverted')) {
        button.textContent = 'Regular Sizing?'
      } else {
        button.textContent = 'Custom Sizing?'
      }
    },

    openModal: (e) => {
      const trigger = e.target.closest('.product-image-modal-trigger')
      const modal = document.querySelector('.product-image-modal')
      const index = trigger.dataset.index
      const target = modal.querySelector(`[data-index="${index}"]`)

      modal.scrollTop = target.getBoundingClientRect().y - (window.matchMedia('(min-width: 768px)').matches ? 20 : 10)

      modal.classList.add('is-active')

      document.documentElement.classList.add('has-modal')
    },

    closeModal: (e) => {
      const modal = e.target.closest('.product-image-modal')
      modal.classList.remove('is-active')
      document.documentElement.classList.remove('has-modal')
    },
  }

  fn.init()
}

const setupFooterLogo = () => {
  const colors = [
    '#0C7359',
    '#F05F21',
    '#1A24F0',
    '#F6EC47',
    '#D0D0D0',
    '#35D57A',
    '#7B4D39',
    '#E22A00',
    '#883C3F',
    '#B070DC',
    '#B5AB41',
    '#BB795B',
    '#35E12E',
    '#009F7F',
    '#1A3283',
    '#81763B',
  ]

  on('mouseover', '.footer-logo path', (e) => {
    e.target.style.fill = colors[Math.floor(Math.random() * colors.length)]
  })
}

const init = () => {
  setHeights()
  setupHeader()
  setupNavigation()
  setupSliders()
  setupProduct()
  setupScroll()
  setupFooterLogo()

  window.addEventListener('resize', setHeights)

  document.documentElement.classList.add('is-init')
}

setColors()
document.addEventListener('DOMContentLoaded', init)
